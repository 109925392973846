<template>
  <div>
    <CCard>
      <CCardBody>
        <template v-if="isEdit">
          <CRow class="input">
            <CCol sm="3">
              <h6>Partner ID:</h6>
            </CCol> 
            <CCol sm="9">
              <h6>
                <strong>{{this.id}}</strong>
              </h6>
            </CCol>
          </CRow>
        </template>
        <ValidationObserver ref="observer" @submit.prevent="save">
          <ValidationProvider v-slot="{ errors }" name="name" rules="required">
            <CInput
              class="input"
              label="Partner Name:"
              placeholder="Partner Name"
              horizontal
              v-model="name"
            />
            <CRow>
              <CCol :sm="{offset:'3'}"></CCol>
              <CCol :sm="9">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>

          <ValidationProvider
            v-if="!isEdit"
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >
            <CInput class="input" label="Email:" placeholder="Email" horizontal v-model="email" />
            <CRow>
              <CCol :sm="{offset:'3'}"></CCol>
              <CCol :sm="9">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </ValidationObserver>

        <template v-if="isEdit">
          <CRow class="input">
            <CCol sm="3">
              <h6>Email:</h6>
            </CCol>
            <CCol sm="9">
              <h6>
                <strong>{{email}}</strong>
              </h6>
            </CCol>
          </CRow>
        </template>

        <CButton
          block
          color="primary"
          class="button-save"
          @click="save"
        >{{partnerId?"Save":"Create"}}</CButton>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    handler: Function,
    // eslint-disable-next-line vue/require-default-prop
    partnerId: String,
    // eslint-disable-next-line vue/require-default-prop
    partner: Object,
  },

  data() {
    return {
      options: [
        {
          label: 'Child Pool',
          value: 'CHILD'
        },
        {
          label: 'Affiliate',
          value: 'AFFILIATE'
        }
      ],
      name: '',
      type: 'CHILD',
      email: '',
      isEdit: false,
    };
  },

  mounted() {
    const checkId = this.$route.params.id;

    if (checkId) {
      this.id = this.partner.id;
      this.name = this.partner.name;
      this.type = this.partner.partner_type;
      this.email = this.partner.email;
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
  },

  methods: {
    save() {
      this.$refs.observer.validate().then(isValid => {
        if (!isValid) {
          return;
        } else {
          this.handler(this.name, this.type, this.email);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-save {
  width: 120px;
  float: right;
  margin-top: 5px;
}

.input {
  margin-top: 1em;
  margin-bottom: 5px;
}

.error-msg {
  color: red;
}
</style>
